.select-field-container {
  margin-top: 1rem;
}

.field-error {
  color: #f44336;
  font-size: 0.75rem !important;
  margin-top: 4px;
}

.select-field-container.hide {
  display: none;
}
