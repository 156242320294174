.Table--header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  h2 {
    font-size: 1.125rem;
    font-weight: 500;
  }

  h3 {
    margin: 0;
    margin-block: 20px;
  }
  h5 {
    margin: 0;
    margin-bottom: 20px;
    margin-top: -15px;
    color: #bababa;
    text-transform: uppercase;
  }
}

.Mui--table {
  thead,
  tbody {
    > tr {
      > th,
      td {
        width: 10%;
        &:first-child {
          padding: 4px 25px 4px 60px;
        }
        &:last-child {
          width: 0.005% !important;
          padding: 4px 60px 4px 25px;
        }
      }
    }
  }

  thead,
  tbody {
    > tr > th,
    td {
      font-size: 0.75rem;
      padding: 4px 25px;
    }
  }

  thead {
    background-color: #f3f5fb;

    > tr > th,
    td {
      font-size: 0.75rem;
      font-weight: 800;
    }
  }

  tbody {
    > tr > th,
    td {
      font-size: 0.875rem;
      font-weight: 400;
    }
    tr {
      &:nth-child(even) {
        background-color: #fbfbfb;
      }

      &:nth-child(odd) {
        background-color: #fff;
      }
    }
  }
}

.Transition--status-wrapper:last-child,
.Trip--event-body {
  margin-bottom: 12px;
}

.Table--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.NewTrip--button {
  min-width: max-content !important;
  width: max-content !important;
  height: 39px !important;

  span.abbr {
    display: none;
  }
}

.Table--cell {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.nowrap {
    white-space: nowrap;
  }

  p {
    margin: 0;
  }
}

.Arrow--icon {
  width: 16px !important;
  opacity: 0.125;

  &.desc {
    transform: scaleY(-1);
  }
  &.asc {
    transform: scaleY(1);
  }
}

.Mui--tablecell {
  height: 28px !important;
  color: #686973 !important;
  cursor: default;

  &:hover {
    .Arrow--icon {
      opacity: 0.2;
      transform: scaleY(-1);

      &.desc {
        transform: scaleY(1);
      }
      &.asc {
        transform: scaleY(-1);
      }
    }
  }
}

.sortedByOpe,
.sortedByName,
.sortedByStatus,
.sortedByOrigin,
.sortedByDest,
.sortedBy {
  opacity: 1 !important;
}

.Filter--wrapper {
  display: flex;
  align-items: center;
}

.User--filter-button {
  display: none !important;
}

.Filter--button {
  height: 36px;
  margin-right: 10px !important;

  .MuiButton-label {
    span.abbr {
      display: none;
    }
    span.noabbr {
      display: flex;
      align-items: center;
    }
  }
}

.Filter--wrapper {
  position: relative;
  width: 100%;
}

.Search--section {
  display: none;
}

.Search--section,
.Filter--section {
  p {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 6px;
    margin-top: 16px;
  }
}

.Select--filter {
  width: 100% !important;
  height: 2.4em !important;

  div {
    font-size: 0.875rem;
  }
}

.Searchbar--input {
  width: 252px;
  padding: 11px 14px 11px 14px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}

@media screen and (max-width: 740px) {
  .Filter--button .MuiButton-label {
    span.noabbr {
      display: none;
    }
    span.abbr {
      display: flex;
    }
  }

  .NewTrip--button {
    width: 45px !important;

    span.noabbr {
      display: none;
    }
    span.abbr {
      display: block;
    }
  }
}

@media screen and (max-width: 622px) {
  .User--filter-button {
    display: flex !important;
  }
  .Search--section {
    display: block;
  }
}
