.Starting {
  margin-left: 25px;

  p {
    margin: 0;
  }
}

.Container {
  display: grid;
  grid-gap: 110px;
  row-gap: 70px;
  column-gap: 90px;
  width: 100%;
  justify-content: center;
  padding-top: 50px;
  grid-template-columns: repeat(auto-fit, minmax(160px, 2fr));
  justify-items: center;

  .Tool {
    width: 180px;
    height: 180px;
    position: relative;
    background: linear-gradient(45deg, #353535, #14747b);
    border-radius: 10px;
    box-shadow: 6px 6px 16px -2px rgba(0, 0, 0, 0.45);
    color: white;
    transition: transform 150ms ease-in-out;
    cursor: pointer;

    &:active {
      transform: scale(0.96);
    }

    svg {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80px;
    }

    strong {
      position: absolute;
      font-size: 0.8125rem;
      bottom: 14px;
      width: 100%;
      text-align: center;
      font-weight: 600;
    }
  }
}
