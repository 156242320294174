.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.row-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  width: 800px;
}

.input-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.buttons-container {
  flex: 1;
  justify-content: space-around;
}

@media only screen and (max-width: 700px) {
  .row-container {
    width: 100%;
  }
}
