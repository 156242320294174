.Footer--btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 1rem;

  .Footer--submit {
    width: 10em;
  }
}

.card-container {
  padding: 10px;
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
  border: 1px solid #e2e0e0;
}

.transition-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 30px;
}

.transition-status-title {
  font-size: 1.6em;
  font-weight: bold;
}

.event-title {
  font-size: 1.6em;
  font-weight: bold;
}

.Show--imgs {
  width: 100%;
  color: #567ebf;
  display: inline-flex;
  justify-content: flex-end;
  margin-block: 8px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
}

.Image--container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.65);
  z-index: 999;

  &.show {
    display: flex !important;
  }
}

.Image--wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: clip;
  width: 100%;
  height: 100%;
}

.Close--button {
  color: white;
  position: absolute;
  top: 18px;
  right: 20px;
  cursor: pointer;
  width: 1.25em !important;
  height: 1.25em !important;
  padding: 6px;
  border-radius: 100%;
  z-index: 1000;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.45);
  }
}

.Image {
  max-width: 70%;
  height: 70%;
  border-radius: 4px;
  object-fit: contain;
}

.Slider--body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow-x: scroll;
  counter-reset: item;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  width: 100%;
}

.Slider--image {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  counter-increment: item;
  list-style: none;
}

.Slide--snapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scroll-snap-align: center;
  justify-content: center;
  align-items: center;
}

.Slide--nav-buttons {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  .Chevron {
    width: 2em !important;
    height: 2em !important;
    color: rgba($color: #fff, $alpha: 0.45);
    cursor: pointer;
  }
}
