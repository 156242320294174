.Filter--panel {
  position: absolute;
  top: 48px;
  left: 0;
  z-index: 9;
  padding: 18px;
  border-radius: 4px;
  background-color: white;
  box-shadow: -1px 11px 14px -7px rgba(0, 0, 0, 0.28);
  border: 1px solid #c4c4c4;

  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
}

.Filters--button {
  float: right;
  margin-top: 12px !important;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
