.Action--buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Table--tabs {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 12px;
  width: max-content;
  background: #fbfbfb;
  border-radius: 4px;
}

.Table--tabs-content.selected {
  border-bottom: 2px solid #3f51b5;
}

.Table--tabs-content {
  background: none;
  border: none;
  width: max-content;
  padding: 12px 26px;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid #c4c4c4 !important;
  cursor: pointer;
}
