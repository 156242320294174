.Search--container {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 0;
}

.Searchbar--button {
  min-width: 0 !important;
  border-radius: 0 4px 4px 0px !important;
  min-height: 39px;

  .Search--icon {
    width: 14px !important;
    height: 25px !important;
  }
}

.Search--formcontrol {
  .MuiInputLabel-formControl {
    top: -7px;
    left: 9px;
    background-color: white;
    padding: 0 4px;
    z-index: 1;
  }
}

.Seach--filter-select {
  padding: 11px 32px 11px 13px !important;
  border-radius: 4px 0 0 4px !important;
  width: 180px !important;
  font-size: 0.875rem !important;

  > div {
    padding: 0 !important;
  }
}

@media screen and (max-width: 622px) {
  .Search--formcontrol {
    display: none !important;
  }
}

@media screen and (max-width: 622px) {
  .Search--container {
    width: 100% !important;
    margin-right: 10px;
  }
}
