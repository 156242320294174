.thumb-container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin-top: 20px;
  overflow: scroll hidden;
}

.ideal-size-message {
  border-radius: 5px;
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  color: red;
  font-style: italic;
  font-size: 0.8em;
}

.Multiimg-upload-button {
  width: 13em;
  margin-top: 10px !important;
}

.Multiimg-img {
  height: 200px;
  object-fit: contain;
}

.Multiimg-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Multiimg-icon-btn {
  margin-right: -7px !important;
  padding: 2px !important;
}
